// @flow strict

import moment from "moment-timezone";
import * as React from "react";

export type ID = string;
export type Time = number;

export type Person = {
  id: ID,
  name: string,
  email: string,
  backupURL: string,
  isStaff: boolean,
};

export type Slot = {
  id: ID,
  name: string,
  students: Array<Person>,
  startTime: Time,
  endTime: Time,
  callLink: ?string,
  location: string,
};

export type Section = {
  id: ID,
  staff: ?Person,
  students: Array<Person>,
  description: string,
  capacity: number,
  tags: Array<string>,
  needsEnrollmentCode: boolean,
  canSelfEnroll: boolean,
  slots: Array<Slot>,
  enrollmentCode: ?string,
};

const AttendanceStatus = {
  present: "Present",
  excused: "Excused",
  absent: "Absent",
};

export { AttendanceStatus };

export type AttendanceStatusType = $Keys<typeof AttendanceStatus>;

type Attendance = {
  student: Person,
  status: AttendanceStatusType,
};

export type Session = {
  id: ID,
  startTime: Time,
  attendances: Array<Attendance>,
};

type AttendanceDetails = {
  ...Attendance,
  section: ?Section,
  session: Session,
};

export type PersonDetails = {
  ...Person,
  isAdmin?: boolean,
  attendanceHistory: Array<AttendanceDetails>,
};

export type SlotDetails = {
  ...Slot,
  sessions: Array<Session>,
};

export type SectionDetails = {
  ...Section,
  slots: Array<SlotDetails>,
};

export type CourseConfig = {
  canStudentsJoin: boolean,
  canStudentsChange: boolean,
  canTutorsChange: boolean,
  canTutorsReassign: boolean,
  message: string,
};

export type State = {
  course: string,
  enrolledSection: ?Section,
  sections: Array<Section>,
  taughtSections: Array<Section>,
  currentUser: ?PersonDetails,
  config: CourseConfig,
};

export const TZ = "America/Los_Angeles";

export function sectionTitle(section: ?Section): React.MixedElement {
  return section == null ? (
    <>Deleted Section</>
  ) : (
    <>
      {section.staff == null ? "Unknown TA" : `${section.staff.name}'s section`}{" "}
    </>
  );
}

export function nextSessionStartTime(slot: Slot, dayOffset: number = 0) {
  const time = moment.unix(slot.startTime).tz(TZ);
  while (time.isBefore(moment().add(dayOffset, "days"))) {
    time.add(7, "days");
  }
  return time.local();
}

export function sessionStartTimes(slot: Slot) {
  let time = moment.unix(slot.startTime).tz(TZ);
  const out = [];
  while (time.isBefore(moment().subtract(1.5, "days"))) {
    out.push(time.clone().local());
    time = time.clone().add(7, "days");
  }
  return out;
}

export function slotInterval(slot: Slot): React.MixedElement {
  const isPT = moment.tz.guess() === TZ;
  const useLocalString = document.cookie
    .split("; ")
    .find((row) => row.startsWith("useLocal="));
  const useLocal =
    useLocalString && useLocalString.split("=")[1] === "true" ? true : false;

  const tz = useLocal ? moment.tz.guess() : TZ;

  const firstSectionStartTime = moment.unix(slot.startTime).tz(tz);
  const startTime = nextSessionStartTime(slot);
  const endTime = startTime
    .clone()
    .add(slot.endTime - slot.startTime, "seconds");
  return (
    <>
      {firstSectionStartTime.tz(tz).format("ddd")}{" "}
      {startTime.tz(tz).format("h:mma")} &rarr; {endTime.tz(tz).format("h:mma")}
      {!isPT && <> ({moment().tz(tz).format("z")})</>}
    </>
  );
}

export function sectionInterval(
  section: Section | SectionDetails
): React.MixedElement {
  const slotIntervals = section.slots.map((slot) => slotInterval(slot));
  const title = [];
  for (const interval of slotIntervals) {
    title.push(interval);
    title.push(" / ");
  }
  title.pop();
  return <>{title}</>;
}

export function sortedSlots<T: Slot | SlotDetails>(slots: Array<T>): Array<T> {
  return slots
    .slice()
    .sort((slot1, slot2) =>
      nextSessionStartTime(slot1).diff(nextSessionStartTime(slot2))
    );
}
