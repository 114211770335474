// @flow strict

import Button from "react-bootstrap/Button";
import * as React from "react";
import type { Section, SectionDetails, Slot, SlotDetails } from "./models";

type Props = {
  section: Section | SectionDetails,
};

type T = Slot | SlotDetails;

export default function SectionJoinCallButtons({ section }: Props) {
  return (
    <>
      {(section.slots: $ReadOnlyArray<T>)
        .filter((slot) => slot.callLink)
        .map((slot) => (
          <>
            <Button variant="success" href={slot.callLink} target="_blank">
              Enter {slot.name} Call
            </Button>{" "}
          </>
        ))}
    </>
  );
}
