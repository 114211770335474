// @flow strict

import Card from "react-bootstrap/Card";
import * as React from "react";
import { slotInterval } from "./models";
import type { Slot } from "./models";

type Props = {
  slot: Slot,
};

export default function SlotCard({ slot }: Props) {
  return (
    <Card key={slot.id} body>
      <Card.Subtitle>
        {slot.name} @ {slot.location}
      </Card.Subtitle>
      <Card.Text>{slotInterval(slot)}</Card.Text>
    </Card>
  );
}
